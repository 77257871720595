import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import LoginDialog from './Login';


function ResponsiveAppBar( ) {

const [messages, setMessages] = useState(null);
const [updatedMessage, setUpdatedMessage] = useState("");
const token = localStorage.getItem('UUID');
const tokenRole = localStorage.getItem('URID');

if (token != null) {
  //console.log("Token not null")
  var sendthistoken = token
}
  else {
    //console.log("Token is null")
  }

  
 
const URL = process.env.REACT_APP_URL
const sendtoken = localStorage.getItem('VertexToken');


  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

const [anchorEl, setAnchorEl] = React.useState(null);
const [Loginopen, setLoginOpen] = useState(false);

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const handleLoginOpen = () => {
    console.log("Pressed Login")
    setLoginOpen(true);
  };
  function handleManagement() {
    window.location.href = "/management"
    setAnchorEl(null);
  }  
  function handleSalesOrders() {
    window.location.href = "/salesorders"
    setAnchorEl(null);
  }
  function handlePurchaseOrders() {
    window.location.href = "/purchaseorders"
    setAnchorEl(null);
  }
  function handlePacklist() {
    window.location.href = "/packlist"
    setAnchorEl(null);
  }
  function handlePicklist() {
    window.location.href = "/picklist"
    setAnchorEl(null);
  }
  function handleMaterials() {
    window.location.href = "/materials"
    setAnchorEl(null);
  }
  function handleProfile() {
    window.location.href = "/profile"
    setAnchorEl(null);
  }
  function handleAdmin() {
    window.location.href = "/admin"
    setAnchorEl(null);
  }
  function handleMessages() {
    window.location.href = "/messages"
    setAnchorEl(null);
  }
  function handleLogout() {
    localStorage.removeItem("UUID");
    localStorage.removeItem("URID");
    localStorage.removeItem("VertexName");
    localStorage.removeItem("VertexToken");
    localStorage.removeItem("Vhome");
    window.location.href = "/"
    setAnchorEl(null);
  }
  
  if (tokenRole === '0') {
      //Not Logged In
      console.log("Not Logged In");
      var hover = <menu>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={{
              display: { xs: 'block', md: 'none', width: '40%' },
            }}
          >
            <MenuItem key={"login"} onClick={handleLoginOpen}>
              <Typography textAlign="center">Login</Typography>
            </MenuItem>
            <MenuItem key={"contact"} onClick={handleLoginOpen}>
              <Typography textAlign="center">Contact Us</Typography>
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
            key={"login"} onClick={handleLoginOpen}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Login
          </Button>
          <Button
            key={"contact"} onClick={handleProfile}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Contact Us
          </Button>
        </Box>
      </menu>
      var logedInMenu = <div style={{display:'inline-flex'}}>
        </div>
      console.log(hover)
    }
    else if (tokenRole === '1') {
      console.log("Production");
      var hover = <menu>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem key={"profile"} onClick={handleProfile}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            
            <MenuItem key={"messages"} onClick={handleMessages}>
              <Typography textAlign="center">Messages</Typography>
            </MenuItem>
            <MenuItem key={"logout"} onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>

          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
            key={"profile"} onClick={handleProfile}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Profile
          </Button>
          <Button
            key={"messages"} onClick={handleMessages}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Messages
          </Button>
          {messages > 0 ? <span className="e-badge e-badge-info e-badge-overlap e-badge-dot messagecount">{messages}</span> : null}
          
          <Button
            key={"logout"} onClick={handleLogout}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Log Out
          </Button>

        </Box>
      </menu>
      var logedInMenu = <div style={{display:'inline-flex'}}>
      <Button
            key={"SalesOrders"}
            onClick={handleSalesOrders}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Sale Orders
          </Button>
          <Button
            key={"PurchaseOrders"}
            onClick={handlePurchaseOrders}
            sx={{ my: 2, color: 'white', display: 'block' }}
            >
            Purchase Orders
          </Button>

          <Button
            key={"PackList"}
            onClick={handlePacklist}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            PackList
          </Button>

          <Button
            key={"materials"}
            onClick={handleMaterials}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Materials
          </Button>
          <Button
            key={"PickList"}
            onClick={handlePicklist}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Picklists
          </Button>
          </div>
      console.log(hover)
    }
    else if (tokenRole === '2') {
      console.log("RMA");
      console.log(tokenRole)
      var hover = <menu>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem key={"profile"} onClick={handleProfile}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
           
            <MenuItem key={"messages"} onClick={handleMessages}>
              <Typography textAlign="center">Messages</Typography>
            </MenuItem>
            <MenuItem key={"logout"} onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>

          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
            key={"profile"} onClick={handleProfile}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Profile
          </Button>
          <Button
            key={"messages"} onClick={handleMessages}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Messages
          </Button>
          {messages > 0 ? <span className="e-badge e-badge-info e-badge-overlap e-badge-dot messagecount">{messages}</span> : null}
          
          <Button
            key={"logout"} onClick={handleLogout}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Log Out
          </Button>

        </Box>
      </menu>
      var logedInMenu = <div style={{display:'inline-flex'}}>
      <Button
            key={"RMA Orders"}
            onClick={handleSalesOrders}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            RMA Orders
          </Button>
          <Button
            key={"PurchaseOrders"}
            onClick={handlePurchaseOrders}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Purchase Orders
          </Button>
          <Button
            key={"materials"}
            onClick={handleMaterials}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Materials
          </Button></div>
      console.log(hover)
      
    }
  else {
      console.log("not loged in no role");
      var hover = <menu>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            className='items'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={{
              display: { xs: 'block', md: 'none', textAlign: 'center' },
            }}
          >
            <MenuItem key={"Login"} onClick={handleLoginOpen} className='items'>
              <Typography textAlign="center">Login</Typography>
            </MenuItem>

          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
            key={"Login"}
            onClick={handleLoginOpen}
            sx={{ my: 2, color: 'white', display: 'block' }}>
            Login
          </Button>
          <Button
            key={"ContactUs"}
            onClick={handleCloseUserMenu}
            sx={{ my: 2, color: 'white', display: 'block' }}
>
            Contact Us
          </Button>
        </Box>
      </menu>
      console.log(hover)
    }
  
  return (
    <AppBar position="static" style={{ zIndex: 500 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Davis
          </Typography>

          {/* Need to Add Control for Login Here */}

          
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Davis
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {logedInMenu}

          </Box>

          <Box sx={{ flexGrow: 0 }}>

            {hover}
          </Box>


        </Toolbar>
      </Container>

      <LoginDialog Loginopen={Loginopen} handleLoginClose={handleLoginClose} />

    </AppBar>


  );
}
export default ResponsiveAppBar;

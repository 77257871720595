import React from 'react';
import Dialog from '@mui/material/Dialog';
import LoginForm from './LoginForm';
 
const LoginDialog = ({ Loginopen, handleLoginClose }) => {
    return (
        <Dialog id={'logindia'} open={Loginopen} onClose={handleLoginClose}>
            <LoginForm handleLoginClose={handleLoginClose} />
        </Dialog>
    );
};
 
export default LoginDialog;
import React from "react";  

const Home = () => {
    return (
    
      <div className="TitlePage">
        <div className='Davis'>
    <h3>Davis</h3>
    </div>
    </div>
      
  );
}

export default Home;
import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header/Header';
import Home from './components/Home/Home';

import './App.css';

function App() {

  return (
    <div>
    <Header/>
    <Router>
    <Routes>
    <Route path="/" element={<Home />} />
      

    </Routes>
    </Router>

    </div>   
  );
}

export default App;

import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { Controller, useForm } from 'react-hook-form';
import './Header.css';

const LoginForm = ({ handleLoginClose }) => {
    console.log("Login Form")
    const { handleSubmit, control } = useForm();
    
    const URL = process.env.REACT_APP_URL
    console.log(URL)
    const onSubmit = data => {
        console.log(data);
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                //"Access-Control-Allow-Origin": "*",
            }
          };
 
console.log(axiosConfig)
// Local
axios.post(URL+'users/authenticate', data, axiosConfig)
// At home
 //axios.post('http://192.168.1.69/users/authenticate', data, axiosConfig)
// Outside
//axios.post('http://rentals.ddns.net/users/authenticate', data, axiosConfig)
.then((res) => {
  console.log("RESPONSE RECEIVED: ", res);
  console.log("RESPONSE RECEIVED: ", res.data.data.role);
  const logintoken = res.data.token;
  const firstName = res.data.data.firstName;
  const role = res.data.data.role;
  const id = res.data.data.id;
  const location = res.data.data.location;
  var obj = {
    "URID": role,
    "UUID": id 
}
    console.log(obj);
  localStorage.removeItem('L2HToke')
  localStorage.setItem('VertexToken', logintoken);
  localStorage.setItem('VertexName', firstName);
  localStorage.setItem('UUID', id);
  localStorage.setItem('URID', role);
  localStorage.setItem('Loc', location);
  //localStorage.setItem('UUID', JSON.stringify(obj));
  window.location.reload();
})
.catch((err) => {
    console.error(err.response.data); 
    console.log("AXIOS ERROR: ", err);
})

    };
 
    return (
        <form className={"loginForm"} onSubmit={handleSubmit(onSubmit)}>
           <h2 className="interest" style={{textAlign:"start"}}>Davis Login</h2>
            
            <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                        label="Email"
                        variant="filled"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                    />
                )}
                rules={{ required: 'Email Required' }}
            />
           
            <Controller
                name="password"
                className="pass"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                    className="pass"
                    label="Password"
                        variant="filled"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="password"
                    />
                )}
                rules={{ required: 'Password required' }}
            />
            <div>
                <Button id={'start'} variant="contained" onClick={handleLoginClose}>
                    Cancel
                </Button>
                <Button id={'end'} type="submit" variant="contained" color="primary">
                    Login
                </Button>
            </div>
        </form>
    );
};
 
export default LoginForm;